/* FONT FACE Lato */

@font-face {
  font-family: 'LeagueSpartan';
  font-weight: normal;
  src: url('./assets/font/League_Spartan/LeagueSpartan-Regular.woff');
}

@font-face {
  font-family: 'LeagueSpartan';
  font-weight: bold;
  src: url('./assets/font/League_Spartan/LeagueSpartan-Bold.woff');
}

/* FIN FONTS */
body {
  margin: 0;
  font-family: 'LeagueSpartan', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #D9D9D9;
}

a {
  text-decoration: none;
}

.MuiButtonBase-root {
  text-transform: inherit !important;
  padding: 7px 25px !important;
  border-radius: 11px !important;
}
.MuiInputBase-input.Mui-disabled {
  -webkit-text-fill-color: #060A23 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s;
}

.MuiSwitch-root > .MuiButtonBase-root {
  padding: 9px !important;
}

.MuiInputBase-root.MuiOutlinedInput-root > input:focus {
  outline: 0px;
}